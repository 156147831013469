.property {
    &--main-slider {
        .carousel-item {
            min-height: 465px;
        }
    }
    &--gallery-sub-image {
        width: 100%;
        height: 0;
        padding-bottom: 52%;
        display: block;
        position: relative;
        background: no-repeat center center;
        background-size: cover;
        &:first-child {
            margin-bottom: 2rem;
        }
        .btn-gallery {
            min-width: 8rem;
            position: absolute;
            right: 1em;
            bottom: 1em;
            padding: 0.5em;
        }
    }
}