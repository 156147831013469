/**
*
*   Theme customization
*
**/
@font-face {
  font-family: 'Inter-Regular';
  font-style: normal;
  font-weight: 400;
  src: local(''),
       url('./assets/fonts/Inter-Regular.ttf') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('./assets/fonts/Inter-Regular.ttf') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
@font-face {
  font-family: 'Inter-Light';
  font-style: normal;
  font-weight: 400;
  src: local(''),
       url('./assets/fonts/Inter-Light.ttf') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('./assets/fonts/Inter-Light.ttf') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
@font-face {
  font-family: 'Inter-Medium';
  font-style: normal;
  font-weight: 400;
  src: local(''),
       url('./assets/fonts/Inter-Medium.ttf') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('./assets/fonts/Inter-Medium.ttf') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
@font-face {
  font-family: 'MADEMirage-Medium';
  font-style: normal;
  font-weight: 400;
  src: local(''),
       url('./assets/fonts/MADEMirage-Medium.otf') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('./assets/fonts/MADEMirage-Medium.otf') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
@font-face {
  font-family: 'MADEMirage-Black';
  font-style: normal;
  font-weight: 400;
  src: local(''),
       url('./assets/fonts/MADEMirage-Black.otf') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('./assets/fonts/MADEMirage-Black.otf') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
$font-family-base: 'Inter-Light',sans-serif;
$font-family-bold: 'Inter-Regular',sans-serif;
$font-sans-serif: 'MADEMirage-Medium',serif;
$font-sans-serif-bold: 'MADEMirage-Black',serif;

$theme-colors: (
    "primary": #00514e,
    "secondary": #ee9d68,
    "body-color": #4d4d4f,

    // Custom colors
    "secondary-high": #c3552f,
    "secondary-med": #f4d5af,
    "secondary-light": #f1e9df,

    "conexion-light": #d7eed3,
    
);
$enable-negative-margins: true;

@import "~bootstrap/scss/bootstrap";




/**
*
*   Plugin styles
*
**/
@import "animate.css/animate.min.css";
@import url('https://cdn.jsdelivr.net/npm/lightgallery@2.4.0/css/lightgallery.css');
@import url('https://cdn.jsdelivr.net/npm/lightgallery@2.4.0/css/lg-zoom.css');
@import url('https://cdn.jsdelivr.net/npm/lightgallery@2.4.0/css/lg-video.css');




/**
*
*   Bootstrap Overrides
*
**/
.accordion-item {
  border: none;
  border-bottom: 1px solid #4d4d4f;
  &:first-of-type {
    border-top: 1px solid #4d4d4f
  }
  .accordion-button:not(.collapsed) {
    color: inherit;
    background-color: #f1e9df;
    box-shadow: none;
  }
  .accordion-button:focus {
    border-color: #f4d5af;
    box-shadow: none;
  }
}
.btn {
  min-width: 10rem;
  padding-top: 1em;
  padding-bottom: 1em;
  padding-left: 2.5em;
  padding-right: 2.5em;
  border-radius: 0;
  font-weight: 600;
  font-family: $font-family-bold;
}
.calendly-badge-widget {
  display: none !important;
}
.carousel-caption {
  bottom: auto;
  top: 3rem;
  h3 {
    font-size: 5vw;
    span {
      line-height: 95%;
      display: inline-block;
    }
  }
  p {
    display: inline-block;
    padding: 0 0.5em;
    background: var(--bs-primary);
    a {
      font-weight: 800;
      text-decoration: none;
    }
  }
  @media screen and (min-width: 1680px) {
    h3 {
      font-size: 6rem;
    }
  }
  @media screen and (max-width: 768px) {
    top: -1rem;
    h3 {
      font-size: 2.5rem;    
    }
  }
}
.carousel-indicators [data-bs-target] {
  width: 15px;
  height: 15px;
  border-radius: 50%;
}
.carousel-item {
  min-height: 80vh;
  background: no-repeat center center;
  background-size: cover;
}
.form-control {
  border: none;
  border-radius: 0;
  background: transparent;
  border-bottom: 1px solid #fff;

  &:focus {
    background-color: rgba(255,255,255,0.1);
    outline: 0;
    box-shadow: none;
    border-color: #fff;
  }

  &::placeholder {
    color: #fff;
  }
}
.form-check {
  .form-check-input[type=checkbox] {
    border-radius: 0;
    background-color: transparent;
    border: 1px solid #fff;
  }
  .form-check-label {
    color: #fff;
  }
}
.navbar-nav {
  .nav-link {
    position: relative;
    color: #fff;
    font-family: 'Inter-Medium', sans;
    font-size: 90%;
    //font-weight: 600;
    line-height: 200%;
    &.active {
      color: var(--bs-secondary-med);
      border-bottom: 1px solid;
    }
    @media screen and (min-width: 768px){
      padding: 0 !important;
      margin: 0 0.5em;
    }
  }
  .dropdown-menu {
    border-radius: 0;
    border: none;
    color: #fff;
    background: #00514e;
    padding-bottom: 0;
    a {
      padding: 0.7em 1em;
      color: #fff;
      font-weight: 600;
      &:hover {
        color: #00514e;
        background: #d7eed3;
      }
    }
  }
}



/**
*
*   Custom elements
*
**/
body {
  font-size: 19px;
  color: #4d4d4f;
}
h2 {
  font-size: 2.5rem;
}
img {
  max-width: 100%;
}
#amenities {
  .carousel {
    border-radius: 50%;
    overflow: hidden;
    .carousel-item {
      min-height: 500px;
    }
  }
}
#amenities-icons {
  .features-icon {
    width: 75px;
    height: 75px;
    background-size: 65%;
    &.icon--card {
      background-size: 50%;
    }
    &.icon--kitchen {
      background-size: 50%;
    }
  }
}
#features {
  .feature-item {
    max-width: 340px;
  }
  h5 {
    font-size: 2em;
    margin-bottom: 0.25em;
  }
  .features-icon {
    width: 100px;
    height: 100px;
    margin-bottom: 0.75em;
    display: inline-block;
    background-size: 85%;
  }
}
#fraccional-pros {
  .icon {
    width: 100px;
    height: 100px;
  }
  h5 {
    font-size: 1.75em;
  }
  .fractional-pros {
    &--item {
      width: 375px;
    }
  }
}
#fractional-tbl {
  th {
    font-size: 90%;
  }
  th,
  td {
    background: var(--bs-secondary-light);
  }
  .icon {
    width: 2rem;
    height: 2rem;
  }
}
footer {
  a {
    text-decoration: none;
    color: inherit;
  }
  .icon {
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
  }
  .author-logo {
    height: 3rem;
    display: block;
    margin: 0 0 0.5em;
    vertical-align: baseline;
    @media screen and (max-width: 767px){
      margin-left: auto;
      margin-right: auto;
    }
  }
}
.amenities-list {
  text-align: left;
}
.apartment {
  &--name {
    font-size: 1.5em;
  }
  .btn {
    padding: 0.5em;
  }
  &--grid {
    position: relative;
    padding-bottom: 1em;
    .apartment {
      &--link {
        color: inherit;
        text-decoration: none;
      }
      &--image {
        height: 0;
        padding-bottom: 65%;
        margin-bottom: 0.5em;
        display: block;
        background: no-repeat center center;
        background-size: cover;
        cursor: pointer;
      }
      &--title {
        font-size: 1rem;
        text-align: left;
        cursor: pointer;
      }
      &--name {
        font-size: 1rem;
        font-weight: 700;
      }
      &--badge {
        font-size: 75%;
        display: inline-block;
      }
      &--details {
        text-align: left;
        font-size: 0.85rem;
      }
    }
  }
}
.apartment-list {
  &--grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    grid-gap: 10px;
  }
}
.apartments-hero {
  .hero-intro {
    max-width: 550px;
    padding: 0;
    margin: 3em auto;
  }
  h1 {
    text-shadow: 0px 0px 5px rgba(0,0,0,0.5);
  }
}
.app-features {
  h5 {
    font-weight: 600;
  }
}
.app-links {
  .btn {
    padding: 0;
    margin: 0;
  }
}
.app-wp {
  h5 {
    font-weight: 600;
  }
  img {
    border: 5px solid #fff;
    border-radius: 12px;
  }
}
.bg-stripe {
  position: relative;
  &:before {
    width: 100%;
    height: 300px;
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    background: var(--bs-secondary);
    z-index: 0;
  }
  .container {
    position: relative;
    z-index: 1;
  }
  &--darker {
    &::before {
      background: var(--bs-secondary-light);
    }
  }
  &--green {
    &::before {
      background: var(--bs-conexion-light);
    }
  }
}
.camp {
  
  &--bg {
    background:  no-repeat center center;
    background-size: cover;
  }
  &--caption {
    max-width: 9em;
    padding-top: 2em;
    padding-bottom: 1em;
    font-size: 2.5em;
    line-height: 120%;
    margin-left: -3em;
  }
  @media screen and (max-width: 767px){
    &--caption {
      margin-left: -6.5em;
      font-size: 1.75em;
      text-align: right;
    }    
  }
}
.community-hero {
  .hero-intro {
    width: 665px;
    max-width: 100%;
    padding: 0;
    margin: 3em auto;
  }
}
.contact-form {
  h2 {
    font-size: 3.5em;
  }
  .calendly-inline-widget {
    min-height: 905px !important;
  }
  @media screen and (max-width: 768px) {
    h2 {
      font-size: 2.5em;
    }
  }
}
.contact-hero {
  .hero-banner {
    > div:nth-child(2){
      position: relative;
      z-index: 1;
    }
  }
  video {
    object-fit: cover;
  }
}
.deco-letter {
  font-size: 9rem;
  line-height: 100%;
  font-family: $font-sans-serif-bold;
}
.directory-venue {
  .icon {
    width: 3em;
    height: 3em;
  }
}
.font-styled {
  font-family: $font-sans-serif;
}
.font-styled-bold {
  font-family: $font-sans-serif-bold;
}
.hero-banner {
  position: relative;
  padding: 13rem 0 11rem;
}
.hero-bg {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
.hero-intro {
  padding: 3em 35%;
  &--extend {
    padding-left: 20%;
    padding-right: 20%;
  }
  @media screen and (max-width: 768px) {
    padding: 2em 5%;
  }
}
.icon {
  width: 1em;
  height: 1em;
  display: inline-block;
  background: no-repeat center center;
  background-size: 85%;
  &--accesible {
    background-image: url('./assets/images/ico-accesible.svg');
    &--color {
      background-image: url('./assets/images/ico-accesible-color.svg');
    }
  }
  &--amenidades {
    background-image: url('./assets/images/ico-amenidades.svg');
    &--color {
      background-image: url('./assets/images/ico-amenidades-color.svg');
    }
  }
  &--resort {
    background-image: url('./assets/images/ico-resort.svg');
    &--color {
      background-image: url('./assets/images/ico-resort-color.svg');
    }
  }
  &--fraccional {
    background-image: url('./assets/images/ico-fraccional.svg');
    &--color {
      background-image: url('./assets/images/ico-fraccional-color.svg');
    }
  }
  &--equipado {
    background-image: url('./assets/images/ico-equipado.svg');
    &--color {
      background-image: url('./assets/images/ico-equipado-color.svg');
    }
  }
  &--app {
    background-image: url('./assets/images/ico-app.svg');
    background-size: 80%;
    &--color {
      background-image: url('./assets/images/ico-equipado-color.svg');
    }
  }
  &--app--2 {
    background-image: url('./assets/images/ico-app-2.svg');
    background-size: 80%;
  }
  &--play {
    background-image: url('./assets/images/ico-play.svg');
    background-size: 80%;
  }
  &--facebook {
    background-image: url('./assets/images/ico-facebook.svg');
    background-size: 80%;
  }
  &--instagram {
    background-image: url('./assets/images/ico-instagram.svg');
    background-size: 80%;
  }
  &--linkedin {
    background-image: url('./assets/images/ico-linkedin.svg');
    background-size: 80%;
  }
  &--youtube {
    background-image: url('./assets/images/ico-youtube.svg');
    background-size: 80%;
  }
  &--whatsapp {
    background-image: url('./assets/images/ico-whatsapp.svg');
    background-size: 80%;
  }
  &--propiedad {
    background-image: url('./assets/images/ico-propiedad.svg');
    background-size: 80%;
  }
  &--plusvalia {
    background-image: url('./assets/images/ico-plusvalia.svg');
    background-size: 80%;
  }
  &--flexibilidad {
    background-image: url('./assets/images/ico-flexibilidad.svg');
    background-size: 80%;
  }
  &--comodidad {
    background-image: url('./assets/images/ico-comodidad.svg');
    background-size: 80%;
  }
  &--checked {
    background-image: url('./assets/images/ico-check.svg');
    background-size: 80%;
  }
  &--none {
    background-image: url('./assets/images/ico-none-color.svg');
    background-size: 80%;
  }

  &--car {
    background-image: url('./assets/images/ico-car.svg');
    background-size: 80%;
  }
  &--games {
    background-image: url('./assets/images/ico-games.svg');
    background-size: 80%;
  }
  &--card {
    background-image: url('./assets/images/ico-card.svg');
    background-size: 80%;
  }
  &--dice {
    background-image: url('./assets/images/ico-dice.svg');
    background-size: 80%;
  }
  &--kitchen {
    background-image: url('./assets/images/ico-kitchen.svg');
    background-size: 80%;
  }
  &--tables {
    background-image: url('./assets/images/ico-tables.svg');
    background-size: 80%;
  }

  &--sand {
    background-image: url('./assets/images/ico-sand.svg');
    background-size: 80%;
  }
  &--yoga {
    background-image: url('./assets/images/ico-yoga.svg');
    background-size: 80%;
  }
  &--golf {
    background-image: url('./assets/images/ico-golf.svg');
    background-size: 80%;
  }
  &--home {
    background-image: url('./assets/images/ico-home.svg');
    background-size: 80%;
  }
  &--laundry {
    background-image: url('./assets/images/ico-laundry.svg');
    background-size: 80%;
  }
  &--security {
    background-image: url('./assets/images/ico-security.svg');
    background-size: 80%;
  }

  &--gym {
    background-image: url('./assets/images/ico-gym.svg');
    background-size: 80%;
  }
  &--meditation {
    background-image: url('./assets/images/ico-meditation.svg');
    background-size: 80%;
  }
  &--bell {
    background-image: url('./assets/images/ico-bell.svg');
    background-size: 80%;
  }
  &--water {
    background-image: url('./assets/images/ico-water.svg');
    background-size: 80%;
  }
  &--sundesk {
    background-image: url('./assets/images/ico-sun.svg');
    background-size: 80%;
  }
  &--bus {
    background-image: url('./assets/images/ico-bus.svg');
    background-size: 80%;
  }

  &--weather {
    background-image: url('./assets/images/ico-weather.svg');
    background-size: 80%;
  }
  &--grill {
    background-image: url('./assets/images/ico-grill.svg');
    background-size: 80%;
  }
  &--beds {
    background-image: url('./assets/images/ico-beds.svg');
    background-size: 80%;
  }
  &--moto {
    background-image: url('./assets/images/ico-moto.svg');
    background-size: 80%;
  }
  &--bike {
    background-image: url('./assets/images/ico-bike.svg');
    background-size: 80%;
  }
  &--packs {
    background-image: url('./assets/images/ico-packs.svg');
    background-size: 80%;
  }
}
.image-circle {
  @extend .image-square;
  border-radius: 50%;
}
.image-square {
  width: 100%;
  height: 0;
  position: relative;
  padding-bottom: 100%;
  background: no-repeat center center;
  background-size: cover;
  .caption {
    width: 100%;
    position: absolute;
    top: 45%;
    text-align: center;
    color: #fff;
    font-weight: 600;
    text-shadow: 0 0 10px rgba(0,0,0,0.75);
    opacity: 0;
    transition: .3s all linear;
    &--subtitle {
      font-size: 0.8em;
    }
  }
  &:hover {
    .caption {
      opacity: 1;
    }
  }
}
.lang-flag {
  width: 2rem;
  height: 2rem;
  display: inline-block;
  border: 2px solid #fff;
  background: no-repeat center center;
  background-size: cover;
  border-radius: 50%;
  vertical-align: middle;
  margin-right: 0.5rem;
}
.list-ol {
  list-style: none;
  margin: 0;
  padding: 0;
  li {
    display: flex;
    span {
      width: 1em;
      height: 1em;
      display: inline-block;
      font-family: $font-sans-serif;
      font-size: 1em;
      margin-right: 1em;
      text-align: right;
      position: relative;
      &:after {
        width: 2px;
        height: 2px;
        margin-right: -2px;
        display: block;
        position: absolute;
        right: -0.5em;
        top: 50%;
        content: '';
        background: var(--bs-body-color);
        border-radius: 50%;
      }
    }
  }
}
.panorama-viewer {
  height: 500px;
  max-height: 70vh;
  position: relative;
  overflow: hidden;
  &:before {
    width: 3.5rem;
    height: 3.5rem;
    display: block;
    content: '';
    background: url('./assets/images/ico-interactive-hor.png') no-repeat center center;
    background-size: contain;
    position: absolute;
    left: calc(50% - 1.75em);
    bottom: 1.5em;
    z-index: 1;
    animation-name: wiper;
    animation-duration: 2s;
    animation-iteration-count: infinite;
  }
  @keyframes wiper {
    100% {
      transform: translateX(15px);
    }
    50% {
      transform: translateX(0px);
    }
    0% {
      transform: translateX(15px);
    }
  }
  canvas {
    position: absolute;
    max-height: 100%;
    &:nth-child(1),
    &:nth-child(2) {
      display: none;
    }
  }
}
.property-card {
  position: relative;
  &--image {
    width: 100%;
    height: 0;
    padding-top: 50%;
    padding-bottom: 50%;
    border-radius: 50%;
    position: relative;
    background: #000 no-repeat center center;
    background-size: cover;
  }
  &--name {
    margin-bottom: 0;
    padding-bottom: 0;
    font-size: 2.5em;
    line-height: 100%;
    font-family: $font-sans-serif-bold;
    text-transform: uppercase;
    color: var(--bs-secondary)
  }
  .btn {
    padding: 0.5em 1.5em;
  }
}
.size-title {
  font-size: 4rem;
  line-height: 90%;
  @media screen and (max-width: 767px){
    font-size: 3rem;
  }
}
.size-subtitle {
  font-size: 3rem;
  line-height: 90%;
}
.subtitle {
  font-weight: 600;
  font-size: 1rem;
  letter-spacing: 0.05em;
}
.tours-list {
  .btn {
    min-width: auto;
    position: relative;
    border: none !important;
    &:before {
      width: 2px;
      height: 2px;
      display: block;
      content: '';
      background: var(--bs-body-color);
      border-radius: 50%;
      position: absolute;
      left: 0;
      top: calc(50% - 1px);
    }
    &:first-child {
      &::before {
        display: none;
      }
    }
    &.active {
      text-decoration: underline;
    }
  }
  .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    background: none;
    border: none !important;
  }
  .nav-link {
    color: var(--bs-body-color) !important;
    border: none !important;
  }
  .nav-link.active {
    text-decoration: underline;
  }
}
.video-player {
  position: relative;
  //height: 729px;
  width: 100%;
  height: 0;
  //padding-bottom: 56.3%;
  padding-bottom: 44.3%;
  margin: auto;
  background: #000;
  > div {
    position: absolute;
    left: 0;
    top: 0;
  }
  @media screen and (min-width: 768px){
    width: 78.7%;
    padding-bottom: 44.3%;
  }
  @media screen and (min-width: 1920px){
    width: 100%;
    padding-bottom: 56.3%;
  }
}
.video-player-cover {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  background: no-repeat center center;
  background-size: cover;
  z-index: 1;
  &--title {
    font-size: 3.5em;
    @media screen and (max-width: 768px) {
      font-size: 1.2em;
    }
  }
  p {
    font-weight: 600;
  }
  @media screen and (max-width: 767px) {
    //&--title,
    p {
      display: none;
    }
  }
  .icon {
    width: 4em;
    height: 4em;
    display: inline-block;
    transition: .3s all ease-in-out;
    cursor: pointer;
    &:hover {
      transform: scale(1.2,1.2);
    }
  }
}